import * as config from "../constants/config";

let items = null;
let itemBanks = null;
let tasks = null;
let taskTypes = null;
let assessments = null;

export function getItemsData() {
    if (items === null) {
        items = [
            {
                Id: 1,
                Name: "Item 1",
                PromptText: "Prompt text 1 - <strong>VERY IMPORTANT</strong>",
                PromptImage: config.mockFilePath + "items/1/item1.png",
                PromptAudio: config.mockFilePath + "items/1/item1.wav",
                Options: [{
                    Id: 1,
                    Name: "Option 1",
                    ValueText: "Option 1 for Item 1",
                    ValueImage: config.mockFilePath + "items/1/opt1.png",
                    ValueAudio: config.mockFilePath + "items/1/opt1.wav",
                    IsCorrect: true
                },
                    {
                        Id: 2,
                        Name: "Option 2",
                        ValueText: "Option 2 for Item 1",
                        ValueImage: "",
                        ValueAudio: "",
                        IsCorrect: false
                    },
                    {
                        Id: 3,
                        Name: "Option 3",
                        ValueText: "Option 3 for Item 1",
                        ValueImage: "",
                        ValueAudio: "",
                        IsCorrect: false
                    },
                ],
                Links: []
            },
            {
                Id: 2,
                Name: "Item 2",
                PromptText: "Prompt text 2",
                PromptImage: config.mockFilePath + "items/2/item2.jpg",
                PromptAudio: config.mockFilePath + "items/2/item2.wav",
                Options: [{
                    Id: 4,
                    Name: "Option 4",
                    ValueText: "Option 1 for Item 2",
                    ValueImage: "",
                    ValueAudio: "",
                    IsCorrect: true
                }],
                Links: []
            },
            {
                Id: 3,
                Name: "Item 3",
                PromptText: "Prompt text 3",
                PromptImage: config.mockFilePath + "items/3/item3.jpg",
                PromptAudio: config.mockFilePath + "items/3/item3.wav",
                Options: [{
                    Id: 5,
                    Name: "Option 5",
                    ValueText: "Option 1 for Item 3",
                    ValueImage: "",
                    ValueAudio: "",
                    IsCorrect: true
                }],
                Links: [{
                    Href: "3",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 4,
                Name: "Item 4",
                PromptText: "Prompt text 4",
                PromptImage: config.mockFilePath + "items/4/item4.jpg",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "4",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 5,
                Name: "Item 5",
                PromptText: "Prompt text 5",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "5",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 6,
                Name: "Item 6",
                PromptText: "Prompt text 6",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "6",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 7,
                Name: "Item 7",
                PromptText: "Prompt text 7",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "7",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 8,
                Name: "Item 8",
                PromptText: "Prompt text 8",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "8",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 9,
                Name: "Item 9",
                PromptText: "Prompt text 9",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "9",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 10,
                Name: "Item 10",
                PromptText: "Prompt text 10",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "10",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 11,
                Name: "Item 11",
                PromptText: "Prompt text 11",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "11",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
            {
                Id: 12,
                Name: "Item 12",
                PromptText: "Prompt text 12",
                PromptImage: "",
                PromptAudio: "",
                Options: [],
                Links: [{
                    Href: "12",
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            },
        ];
    }

    return items;
}

export function saveItemsData(newItemList) {
    items = newItemList;
}

export function getItemBanksData() {
    if (itemBanks === null) {
        itemBanks = [
            {
                Id: 1,
                Name: "Item Bank 1",
                ItemIds: [1, 2, 2, 1],
                DateCreated: "02/01/2018",
                DateModified: "03/01/2018",
                Links: [{
                    Href: "1",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 2,
                Name: "Item Bank 2",
                ItemIds: [2, 3, 3, 2],
                DateCreated: "02/02/2018",
                DateModified: "03/02/2018",
                Links: []
            },
            {
                Id: 3,
                Name: "Item Bank 3",
                ItemIds: [1, 3, 1],
                DateCreated: "02/03/2018",
                DateModified: "03/03/2018",
                Links: [{
                    Href: "3",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 4,
                Name: "Item Bank 4",
                ItemIds: [4, 5],
                DateCreated: "02/04/2018",
                DateModified: "03/04/2018",
                Links: []
            },
            {
                Id: 5,
                Name: "Item Bank 5",
                ItemIds: [1, 5],
                DateCreated: "02/05/2018",
                DateModified: "03/05/2018",
                Links: [{
                    Href: "5",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 6,
                Name: "Item Bank 6",
                ItemIds: [7, 2],
                DateCreated: "03/06/2018",
                DateModified: "03/06/2018",
                Links: [{
                    Href: "6",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 7,
                Name: "Item Bank 7",
                ItemIds: [2, 1],
                DateCreated: "02/07/2018",
                DateModified: "03/07/2018",
                Links: [{
                    Href: "7",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 8,
                Name: "Item Bank 8",
                ItemIds: [3, 1],
                DateCreated: "12/08/2017",
                DateModified: "01/08/2018",
                Links: [{
                    Href: "8",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 9,
                Name: "Item Bank 9",
                ItemIds: [5, 4],
                DateCreated: "02/09/2018",
                DateModified: "03/09/2018",
                Links: [{
                    Href: "9",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 10,
                Name: "Item Bank 10",
                ItemIds: [4, 5],
                DateCreated: "02/10/2018",
                DateModified: "03/10/2018",
                Links: [{
                    Href: "10",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 11,
                Name: "Item Bank 11",
                ItemIds: [1, 2],
                DateCreated: "10/11/2018",
                DateModified: "11/11/2018",
                Links: [{
                    Href: "11",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
            {
                Id: 12,
                Name: "Item Bank 12",
                ItemIds: [6, 5, 4, 3, 2, 1],
                DateCreated: "09/12/2018",
                DateModified: "10/12/2018",
                Links: [{
                    Href: "12",
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            },
        ];

        addItemsToItemBanks();
    }

    return itemBanks;
}

function addItemsToItemBanks() {
    let tempItems = getItemsData();

    for (let i = 0; i < itemBanks.length; i++) {
        itemBanks[i].Items = [];
        itemBanks[i].NumberOfItems = itemBanks[i].ItemIds.length;
        for (let j = 0; j < itemBanks[i].ItemIds.length; j++) {
            itemBanks[i].Items.push(Object.assign({}, tempItems.filter(f => f.Id === itemBanks[i].ItemIds[j])[0]));
        }
    }
}

export function getItemsArrayForItemBank(itemIds) {
    let tempItems = getItemsData();
    let associatedItems = [];
    for (let j = 0; j < itemIds.length; j++) {
        associatedItems.push(Object.assign({}, tempItems.filter(f => f.Id === itemIds[j])[0]));
    }

    return associatedItems;
}

export function saveItemBanksData(newItemBankList) {
    itemBanks = newItemBankList;
}

export function getTaskTypesData() {
    if (taskTypes === null) {
        taskTypes = [
            {Id: 1, Description: "Audio Only"},
            {Id: 2, Description: "Complete"},
            {Id: 3, Description: "Digit Span"},
            {Id: 4, Description: "Image Audio Reveal"},
            {Id: 5, Description: "Option Image Student Select"},
            {Id: 6, Description: "Proctor Directions"},
            {Id: 7, Description: "Sentence Repetition"},
            {Id: 8, Description: "Timed Naming"},
            {Id: 9, Description: "Kerning Grid"},
            {Id: 10, Description: "Game Play"},
            {Id: 11, Description: "Spelling"}
        ];
    }

    return taskTypes;
}

export function getTasksData() {
    if (tasks === null) {
        tasks = [
            {
                Id: 1,
                Name: "Task 1",
                TaskTypeId: 1,
                TimeLimit: 12,
                ItemsPerPage: 4,
                GridRowTotal: 0,
                GridColumnTotal: 0,
                GridSpacing: 0,
                CatModelType: 1,
                CatModelTypeDescription: "UCAT",
                CatConfig: {
                    Id: 101,
                    TaskId: 1,
                    CatModelType: 1,
                    ItemPoolConfigs: {"1": 1, "2": 1},
                    ItemSelectionAlgorithm: 1,
                    CustomThetaMin: null,
                    CustomThetaMax: null,
                    FixedLength: 1.0,
                    Randomesque: 1.1,
                    StopCriteria: [{
                        Id: 1,
                        StopCriteriaType: "1",
                        StopCriteriaValue: "Hi"
                    },
                        {
                            Id: 2,
                            StopCriteriaType: "2",
                            StopCriteriaValue: "Ya"
                        }],
                    ItemIds: [1, 2]
                },
                AssociatedItemIds: [1, 2],
                Forms: [{
                    Id: 1,
                    Name: "1A",
                    ItemBankId: 1
                },
                    {
                        Id: 2,
                        Name: "1B",
                        ItemBankId: 2
                    }],
                Links: [{
                    Href: "1",
                    Rel: "delete_task",
                    Method: "DELETE"
                }]
            },
            {
                Id: 2,
                Name: "Task 2",
                TaskTypeId: 2,
                TimeLimit: -1,
                ItemsPerPage: 1,
                GridRowTotal: 0,
                GridColumnTotal: 0,
                GridSpacing: 0,
                CatModelType: 2,
                CatModelTypeDescription: "UCAT",
                CatConfig: {
                    Id: 102,
                    TaskId: 2,
                    CatModelType: 2,
                    ItemPoolConfigs: {"1": 1, "2": 1},
                    ItemSelectionAlgorithm: 0,
                    CustomThetaMin: null,
                    CustomThetaMax: null,
                    StopCriteria: [{
                        Id: 22,
                        StopCriteriaType: "2",
                        StopCriteriaValue: ""
                    }],
                    ItemIds: [1]
                },
                AssociatedItemIds: [1],
                Forms: [{
                    Id: 3,
                    Name: "2A",
                    ItemBankId: 3
                },
                    {
                        Id: 8,
                        Name: "8A",
                        ItemBankId: 3
                    }],
                Links: []
            },
            {
                Id: 3,
                Name: "Task 3",
                TaskTypeId: 3,
                TimeLimit: 8,
                ItemsPerPage: 2,
                GridRowTotal: 0,
                GridColumnTotal: 0,
                GridSpacing: 0,
                CatModelType: 1,
                CatModelTypeDescription: "UCAT",
                CatConfig: {
                    Id: 103,
                    TaskId: 3,
                    CatModelType: 1,
                    ItemPoolConfigs: {"1": 1},
                    ItemSelectionAlgorithm: 1,
                    CustomThetaMin: null,
                    CustomThetaMax: null,
                    FixedLength: 1.3,
                    Randomesque: 1.33,
                    StopCriteria: [{
                        Id: 33,
                        StopCriteriaType: "3",
                        StopCriteriaValue: ""
                    },
                        {
                            StopCriteriaType: "4",
                            StopCriteriaValue: ""
                        }],
                    ItemIds: [1, 2]
                },
                AssociatedItemIds: [1, 2],
                Forms: [{
                    Id: 4,
                    Name: "3A",
                    ItemBankId: 4
                }],
                Links: [{
                    Href: "3",
                    Rel: "delete_task",
                    Method: "DELETE"
                }]
            },
            {
                Id: 4,
                Name: "Task 4",
                TaskTypeId: 4,
                TimeLimit: -1,
                ItemsPerPage: 1,
                GridRowTotal: 0,
                GridColumnTotal: 0,
                GridSpacing: 0,
                CatModelType: 4,
                CatModelTypeDescription: "Mastery",
                CatConfig: {
                    Id: 104,
                    TaskId: 4,
                    CatModelType: 4,
                    ItemPoolConfigs: {"1": 1, "2": 1},
                    ItemSelectionAlgorithm: 1,
                    CustomThetaMin: null,
                    CustomThetaMax: null,
                    FixedLength: 1.4,
                    Randomesque: 1.44,
                    StopCriteria: [],
                    ItemIds: []
                },
                AssociatedItemIds: [],
                Forms: [{
                    Id: 5,
                    Name: "4A",
                    ItemBankId: 5
                }],
                Links: []
            },
            {
                Id: 5,
                Name: "Task 5",
                TaskTypeId: 5,
                TimeLimit: 8,
                ItemsPerPage: 10,
                GridRowTotal: 0,
                GridColumnTotal: 0,
                GridSpacing: 0,
                CatModelType: 0,
                CatModelTypeDescription: "",
                CatConfig: {
                    Id: 105,
                    TaskId: 5,
                    CatModelType: 0,
                    ItemPoolConfigs: {"1": 1, "2": 1},
                    ItemSelectionAlgorithm: 0,
                    CustomThetaMin: null,
                    CustomThetaMax: null,
                    FixedLength: 1.5,
                    Randomesque: 1.55,
                    StopCriteria: [],
                    ItemIds: []
                },
                AssociatedItemIds: [],
                Forms: [{
                    Id: 6,
                    Name: "5A",
                    ItemBankId: 4
                }],
                Links: [{
                    Href: "5",
                    Rel: "delete_task",
                    Method: "DELETE"
                }]
            },
            {
                Id: 6,
                Name: "Task 6",
                TaskTypeId: 2,
                TimeLimit: 20,
                ItemsPerPage: 3,
                GridRowTotal: 0,
                GridColumnTotal: 0,
                GridSpacing: 0,
                CatModelType: 0,
                CatModelTypeDescription: "",
                CatConfig: {
                    Id: 106,
                    TaskId: 6,
                    CatModelType: 0,
                    ItemPoolConfigs: {"1": 1, "2": 1},
                    ItemSelectionAlgorithm: 0,
                    CustomThetaMin: null,
                    CustomThetaMax: null,
                    FixedLength: 1.6,
                    Randomesque: 1.66,
                    StopCriteria: [],
                    ItemIds: []
                },
                AssociatedItemIds: [],
                Forms: [{
                    Id: 7,
                    Name: "6A",
                    ItemBankId: 1
                }],
                Links: [{
                    Href: "6",
                    Rel: "delete_task",
                    Method: "DELETE"
                }]
            },
        ];

        addItemBanksToTasks();
    }

    return tasks;
}

function addItemBanksToTasks() {
    let tempItemBanks = getItemBanksData();
    let tempTaskTypes = getTaskTypesData();

    for (let i = 0; i < tasks.length; i++) {
        tasks[i].ItemBanks = [];
        tasks[i].NumberOfForms = tasks[i].Forms.length;
        tasks[i].TaskType = tempTaskTypes.filter(f => f.Id === tasks[i].TaskTypeId)[0].Description;
        for (let j = 0; j < tasks[i].Forms.length; j++) {
            tasks[i].ItemBanks.push(Object.assign({}, tempItemBanks.filter(f => f.Id === tasks[i].Forms[j].ItemBankId)[0]));
        }
    }
}

export function getItemBanksArrayForTask(forms) {
    let tempItemBanks = getItemBanksData();
    let associatedItemBanks = [];
    for (let j = 0; j < forms.length; j++) {
        associatedItemBanks.push(tempItemBanks.filter(f => f.Id === forms[j].ItemBankId)[0]);
    }

    return associatedItemBanks;
}

export function saveTasksData(newTaskList) {
    tasks = newTaskList;
}

export function getAssessmentsData() {
    if (assessments === null) {
        assessments = [
            {
                Id: 1,
                Name: "A1",
                AssessmentType: "Pilot",
                DateCreated: "02/01/2018",
                DateModified: "03/01/2018",
                TaskIds: [1, 2],
                McatTaskGroupCount: 0,
                McatTaskGroups: [],
                Links: [{
                    Href: "1",
                    Rel: "delete_assessment",
                    Method: "DELETE"
                }]
            },
            {
                Id: 2,
                Name: "A2",
                AssessmentType: "Game",
                DateCreated: "02/12/2018",
                DateModified: "03/12/2018",
                TaskIds: [2, 1],
                McatTaskGroupCount: 1,
                McatTaskGroups: [
                    {
                        McatTaskGroupId: 1,
                        AssessmentId: 2,
                        GroupName: "Task Group 1 for Assessment 2",
                        McatModelType: 1,
                        ItemPoolId: 1,
                        Tasks: [1],
                        McatStopCriteria: []
                    }
                ],
                Links: []
            },
            {
                Id: 3,
                Name: "A3",
                AssessmentType: "Pilot",
                DateCreated: "02/03/2018",
                DateModified: "03/03/2018",
                TaskIds: [3, 4],
                McatTaskGroupCount: 1,
                McatTaskGroups: [
                    {
                        McatTaskGroupId: 2,
                        AssessmentId: 3,
                        GroupName: "Task Group 2 for Assessment 3",
                        McatModelType: 1,
                        ItemPoolId: 1,
                        Tasks: [3],
                        McatStopCriteria: []
                    }
                ],
                Links: [{
                    Href: "3",
                    Rel: "delete_assessment",
                    Method: "DELETE"
                }]
            },
            {
                Id: 6,
                Name: "A6",
                AssessmentType: "Pilot",
                DateCreated: "02/23/2018",
                DateModified: "03/23/2018",
                TaskIds: [4, 3],
                McatTaskGroupCount: 0,
                McatTaskGroups: [],
                Links: []
            },
            {
                Id: 4,
                Name: "A4",
                AssessmentType: "Game",
                DateCreated: "02/14/2018",
                DateModified: "03/14/2018",
                TaskIds: [1, 2, 3, 4],
                McatTaskGroupCount: 2,
                McatTaskGroups: [
                    {
                    McatTaskGroupId: 3,
                    AssessmentId: 4,
                    GroupName: "Task Group 3 for Assessment 4",
                    McatModelType: 1,
                    ItemPoolId: 5,
                    Tasks: [1, 2],
                    McatStopCriteria: [{
                        McatStopCriterionId: 1,
                        StopCriteriaType: "1",
                        StopCriteriaValue: "Hi"
                    },
                        {
                            McatStopCriterionId: 2,
                            StopCriteriaType: "2",
                            StopCriteriaValue: "Ya"
                        }]
                },
                    {
                        McatTaskGroupId: 4,
                        AssessmentId: 4,
                        GroupName: "Task Group 4 for Assessment 4",
                        McatModelType: 1,
                        ItemPoolId: 6,
                        Tasks: [3, 4],
                        McatStopCriteria: [{
                            McatStopCriterionId: 3,
                            StopCriteriaType: "4",
                            StopCriteriaValue: "This is the other group"
                        }]
                    }
                ],
                Links: [{
                    Href: "4",
                    Rel: "delete_assessment",
                    Method: "DELETE"
                }]
            },
            {
                Id: 5,
                Name: "A5",
                AssessmentType: "Pilot",
                DateCreated: "02/05/2018",
                DateModified: "03/05/2018",
                TaskIds: [4, 3, 2, 1],
                McatTaskGroupCount: 0,
                McatTaskGroups: [],
                Links: [{
                    Href: "5",
                    Rel: "delete_assessment",
                    Method: "DELETE"
                }]
            },

        ];

        addTasksToAssessments();
    }

    return assessments;
}

function addTasksToAssessments() {
    let tempTasks = getTasksData();

    for (let i = 0; i < assessments.length; i++) {
        assessments[i].Tasks = [];
        assessments[i].NumberOfTasks = assessments[i].TaskIds.length;
        for (let j = 0; j < assessments[i].TaskIds.length; j++) {
            assessments[i].Tasks.push(Object.assign({}, tempTasks.filter(f => f.Id === assessments[i].TaskIds[j])[0]));
        }
    }
}

export function getTasksArrayForAssessment(taskIds) {
    let tempTasks = getTasksData();
    let associatedTasks = [];
    for (let j = 0; j < taskIds.length; j++) {
        associatedTasks.push(Object.assign({}, tempTasks.filter(f => f.Id === taskIds[j])[0]));
    }

    return associatedTasks;
}

export function saveAssessmentsData(newAssessmentList) {
    assessments = newAssessmentList;
}