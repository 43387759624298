let reportData = null;

function getReportingData() {
    if(reportData === null) {
        reportData = { UniqueScores: 100,  UniqueAudioFiles: 67, possibleUploadIssues: 50 };
    }

    return reportData;
}

export {getReportingData};