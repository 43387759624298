import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as routes from "../../components/routes/routesConstants";
import ReportingSearchForm from "./ReportingSearchForm";
import * as reportingActions from "./ReportingActions";
import {Notify} from "../../components/notification/notify";
import ReportingSearchResults from "./ReportingSearchResults";
import moment from "moment";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import $ from "jquery";

export class ReportingPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            hasSubmitted: false,
            errors: {}
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onExportScores = this.onExportScores.bind(this);
        this.onExportAudioFiles = this.onExportAudioFiles.bind(this);
        this.onExportMissingAudioFiles = this.onExportMissingAudioFiles.bind(this);
    }

    componentDidMount(){
        this.props.actions.updatePageTitle_BreadCrumb(routes.REPORTING);

        if(this.props.reportingSearchCriteria !== undefined &&
            this.props.reportingSearchCriteria.reportingSearchPerformed) {
            this.setState({hasSubmitted: true});
            this.props.actions.loadReportingInfo(this.props.reportingSearchCriteria);
        }
        else {
            this.setState({hasSubmitted: false});
            $(document).ready(() => {
                window.setTimeout(function() {
                    PushFocusToElement("StartDate");
                }, 200);
            });
        }
    }

    onDateChange(selectedDates, dateStr, instance) {
        const reportingSearchCriteria = Object.assign({}, this.props.reportingSearchCriteria);
        const searchFieldName = instance.config.class;

        dateStr = (dateStr === "") ? null : dateStr;

        reportingSearchCriteria[searchFieldName] = dateStr;

        reportingSearchCriteria.reportingSearchPerformed = false;
        this.props.actions.saveReportingSearchCriteria(reportingSearchCriteria);
    }

    searchFormIsValid(searchCriteria, errors) {
        let formIsValid = true;

        const { StartDate, EndDate } = searchCriteria;

        if( StartDate === "" ) {
            errors.StartDate = "Start date is required.";
            formIsValid = false;
        }

        if( EndDate === "" ) {
            errors.EndDate = "End date is required.";
            formIsValid = false;
        }

        if(StartDate !== "" && EndDate !== "" && moment(StartDate).diff(moment(EndDate)) > 0) {
            errors.StartDate = "Start date needs to come before or be equal to the end date.";
            formIsValid = false;
        }

        return formIsValid;
    }

    onSearchSubmit(event) {
        event.preventDefault();
        Notify.Clear();

        let errors = {};
        let formIsValid = this.searchFormIsValid(this.props.reportingSearchCriteria, errors);
        this.setState({errors: errors, hasSubmitted: true});

        if(!formIsValid)
            return;

        let reportingSearchCriteria = Object.assign({}, this.props.reportingSearchCriteria);
        reportingSearchCriteria.reportingSearchPerformed = true;
        this.props.actions.saveReportingSearchCriteria(reportingSearchCriteria);

        this.props.actions.loadReportingInfo(reportingSearchCriteria);
    }

    onExportScores(event) {
        event.preventDefault();
        this.getExportFiles("Scores");
    }

    onExportAudioFiles(event) {
        event.preventDefault();
        this.getExportFiles("AudioFiles");
    }

    onExportMissingAudioFiles(event) {
        event.preventDefault();
        this.getExportFiles("MissingAudioFiles");
    }

    getExportFiles(whichExport) {
        let reportingSearchCriteria = Object.assign({}, this.props.reportingSearchCriteria);
        reportingSearchCriteria.Export = whichExport;

        this.props.actions.exportReportData(reportingSearchCriteria);
    }

    render() {
        let {reportingSearchCriteria, uniqueScores, uniqueAudioFiles, possibleUploadIssues} = this.props;

        return (
            <div>
                <ReportingSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    isLoading: this.state.isLoading,
                    reportingSearchCriteria: reportingSearchCriteria,
                    onDateChange: this.onDateChange,
                    hasSubmitted: this.state.hasSubmitted,
                    errors: this.state.errors
                }} />
                {reportingSearchCriteria !== undefined &&
                reportingSearchCriteria.reportingSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <ReportingSearchResults uniqueScores={uniqueScores}
                                            uniqueAudioFiles={uniqueAudioFiles}
                                            possibleUploadIssues={possibleUploadIssues}
                                            onExportScores={this.onExportScores}
                                            onExportAudioFiles={this.onExportAudioFiles}
                                            onExportMissingAudioFiles={this.onExportMissingAudioFiles}
                    />
                </div>
                }

            </div>
        );
    }
}

ReportingPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    reportingSearchCriteria: PropTypes.object,
    uniqueScores: PropTypes.number,
    uniqueAudioFiles: PropTypes.number,
    possibleUploadIssues: PropTypes.number
};

function mapStateToProps(state) {
    return {
        reportingSearchCriteria: state.reporting.reportingSearchCriteria,
        uniqueScores: state.reporting.uniqueScores,
        uniqueAudioFiles: state.reporting.uniqueAudioFiles,
        possibleUploadIssues: state.reporting.possibleUploadIssues
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        reportingActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ReportingPage));
