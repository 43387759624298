import {FormValidation} from "calidation";
import Button from "../../components/buttons/button";
import PropTypes from "prop-types";
import React from "react";
import SelectField from "../../components/inputs/selectField";
import TextField from "../../components/inputs/textField";
import {generateItemSelectionOptions, getItemSelectionAlgorithm} from "./taskCatConfigConstants";
import {generateCatModelOptions, ItemPoolConfigDisplaySpeed} from "../../constants/catModelConstants";
import {hasErrors} from "../../components/form/createErrorList";

const TaskCatConfigInfoForm = ({config}) => {
    if(!config.catConfig || config.catConfig.TaskId === 0)
        return null;

    function itemPoolAccuracyMessage(catModelType, isValidation) {
        let itemPoolAccuracyLabel = "";
        if(displaySpeed(catModelType)) {
            itemPoolAccuracyLabel = " - Accuracy";
        }

        return isValidation ? `Item Pool Configuration${itemPoolAccuracyLabel} is required!` : `Item Pool Configuration${itemPoolAccuracyLabel}`;
    }

    function displaySpeed(catModelType) {
        return ItemPoolConfigDisplaySpeed.includes(catModelType);
    }

    let formConfig = {
        Id: 0,
        CatModelType: 0,
        ItemPoolAccuracy : {
            isGreaterThan: ({fields}) => ({
                message: itemPoolAccuracyMessage(fields.CatModelType, true),
                value: 0
            }),
            isRequired : ({fields}) => ({
                message: itemPoolAccuracyMessage(fields.CatModelType,true),
                value: ""
            }),
        },
        ItemPoolSpeed : {
            isGreaterThan: ({fields}) => ({
                message: "Item Pool Configuration - Speed is required!",
                value: 0,
                validateIf: displaySpeed(fields.CatModelType)
            }),
            isRequired : ({fields}) => ({
                message: "Item Pool Configuration - Speed is required!",
                value: "",
                validateIf: displaySpeed(fields.CatModelType)
            }),
        },
        ItemSelectionAlgorithm: {
            isGreaterThan: {
                message: "Item Selection Algorithm is required!",
                value: 0
            },
            isRequired : {
                message: "Item Selection Algorithm is required!",
                value: ""
            },
        },
        CustomThetaMin: {
            isNumber: "Custom Theta Range Min value must be a number!"
        },
        CustomThetaMax: {
            isNumber: "Custom Theta Range Max value must be a number!",
            isGreaterThan: ({fields}) => ({
                message: "Max must be greater than Min!",
                value: parseFloat(fields.CustomThetaMin),
                validateIf: !isNaN(parseFloat(fields.CustomThetaMin)) && !isNaN(parseFloat(fields.CustomThetaMax))
            })
        },
        FixedLength: {
            isNumber: "Fixed Length value must be a number!"
        },
        Randomesque: {
            isNumber: "Randomesque value must be a number!"
        }
    };

    function createItemSelectionAlgorithmDirections(itemSelectionAlgorithmValue){
        const itemSelectionAlgorithm = getItemSelectionAlgorithm(itemSelectionAlgorithmValue);
        return itemSelectionAlgorithm ? itemSelectionAlgorithm.instructions : "";
    }

    return (
        <FormValidation onSubmit={config.onTaskCatConfigSubmit} config={formConfig} initialValues={config.catConfig} id={"task-Cat-Configuration-form"} >
            {({ fields, errors, submitted, setField }) => (
                <>
                    <div className={"grid-x"}>
                        <div className={"cell medium-5 large-5 "}>
                            <SelectField config={{
                                name: "CatModelType",
                                label: "CAT Model",
                                showLabel: true,
                                options: generateCatModelOptions(true),
                                value: fields.CatModelType.toString(),
                                inputClass: "margin-bottom-0",
                                onChange: (event) => config.onCatModelChange(event, setField),
                                includeDefaultOption: false
                            }} />
                        </div>
                    </div>
                    {submitted && (config.hasFormErrors || hasErrors(errors)) && <p className="text-danger">Please correct the issues as specified.</p>}
                    {
                        config.isCatConfigFormVisible &&
                        <>
                            <input type={"hidden"} name={"Id"} value={fields.Id}/>
                            <Button config={{
                                name: "btnSave",
                                label: "Save",
                                btnClass: "hide",
                                disabled: (config.isLoading || config.shouldDisablePageActions)
                            }}/>
                            <div className={"grid-x"}>
                                <div className={"cell  medium-8 large-8 "}>
                                    <SelectField config={{
                                        name: "ItemPoolAccuracy",
                                        label: itemPoolAccuracyMessage(fields.CatModelType, false),
                                        showLabel: true,
                                        options: config.itemPoolSelectList,
                                        value: fields.ItemPoolAccuracy,
                                        inputClass: "margin-bottom-0",
                                        defaultOption: "Select an Item Pool",
                                        error: (submitted && errors.ItemPoolAccuracy) ? errors.ItemPoolAccuracy : ""
                                    }}/>
                                </div>
                            </div>
                            {displaySpeed(fields.CatModelType) &&
                                <div className={"grid-x"}>
                                    <div className={"cell  medium-8 large-8 "}>
                                        <SelectField config={{
                                            name: "ItemPoolSpeed",
                                            label: "Item Pool Configuration - Speed",
                                            showLabel: true,
                                            options: config.itemPoolSelectList,
                                            value: fields.ItemPoolSpeed,
                                            inputClass: "margin-bottom-0",
                                            defaultOption: "Select an Item Pool",
                                            error: (submitted && errors.ItemPoolSpeed) ? errors.ItemPoolSpeed : ""
                                        }}/>
                                    </div>
                                </div>
                            }
                            <div className={"grid-x"}>
                                <div className={"cell  medium-8 large-8 "}>
                                    <SelectField config={{
                                        name: "ItemSelectionAlgorithm",
                                        label: "Item Selection Algorithm",
                                        showLabel: true,
                                        options: generateItemSelectionOptions(fields.CatModelType),
                                        value: fields.ItemSelectionAlgorithm.toString(),
                                        inputClass: "margin-bottom-0",
                                        defaultOption: "Select an Algorithm",
                                        includeDefaultOption: true,
                                        error: (submitted && errors.ItemSelectionAlgorithm) ? errors.ItemSelectionAlgorithm : ""
                                    }}/>
                                </div>
                            </div>
                            <p>{createItemSelectionAlgorithmDirections(fields.ItemSelectionAlgorithm)}</p>
                            <div className={"grid-x"}>
                                <div className={"cell medium-5 large-5"}>
                                    <TextField config={{
                                        name: "CustomThetaMin",
                                        label: "Custom Theta Range Minimum",
                                        maxLength: 10,
                                        showLabel: true,
                                        value: !fields.CustomThetaMin ? "" : fields.CustomThetaMin.toString(),
                                        error: (submitted && errors.CustomThetaMin) ? errors.CustomThetaMin : ""
                                    }}/>
                                </div>
                            </div>
                            <div className={"grid-x"}>
                                <div className={"cell medium-5 large-5"}>
                                    <TextField config={{
                                        name: "CustomThetaMax",
                                        label: "Custom Theta Range Maximum",
                                        maxLength: 10,
                                        showLabel: true,
                                        value: !fields.CustomThetaMax ? "" : fields.CustomThetaMax.toString(),
                                        error: (submitted && errors.CustomThetaMax) ? errors.CustomThetaMax : ""
                                    }}/>
                                </div>
                            </div>
                            <div className={"grid-x"}>
                                <div className={"cell medium-5 large-5"}>
                                    <TextField config={{
                                        name: "FixedLength",
                                        label: "Fixed Length Test",
                                        maxLength: 10,
                                        showLabel: true,
                                        value: !fields.FixedLength ? "" : fields.FixedLength.toString(),
                                        error: (submitted && errors.FixedLength) ? errors.FixedLength : ""
                                    }}/>
                                </div>
                            </div>
                            <div className={"grid-x"}>
                                <div className={"cell medium-5 large-5"}>
                                    <TextField config={{
                                        name: "Randomesque",
                                        label: "Randomesque Candidates",
                                        maxLength: 10,
                                        showLabel: true,
                                        value: !fields.Randomesque ? "" : fields.Randomesque.toString(),
                                        error: (submitted && errors.Randomesque) ? errors.Randomesque : ""
                                    }}/>
                                </div>
                            </div>
                        </>
                    }
                </>
            )}
        </FormValidation>
    );
};

TaskCatConfigInfoForm.propTypes = {
    config: PropTypes.shape({
        onCatModelChange: PropTypes.func.isRequired,
        onTaskCatConfigSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        catConfig: PropTypes.object,
        shouldDisablePageActions: PropTypes.bool.isRequired,
        message: PropTypes.string,
        itemPoolSelectList: PropTypes.array.isRequired,
        hasFormErrors: PropTypes.bool.isRequired,
        isCatConfigFormVisible: PropTypes.bool.isRequired
    })
};

export default TaskCatConfigInfoForm;
