export const LOAD_ASSESSMENTS_AJAX_SUCCESS = "LOAD_ASSESSMENTS_AJAX_SUCCESS";
export const SAVE_ASSESSMENT_SEARCH_CRITERIA = "SAVE_ASSESSMENT_SEARCH_CRITERIA";
export const DELETE_ASSESSMENT_AJAX_SUCCESS = "DELETE_ASSESSMENT_AJAX_SUCCESS";
export const EDIT_ASSESSMENT_AJAX_SUCCESS = "EDIT_ASSESSMENT_AJAX_SUCCESS";
export const SAVE_ASSESSMENT_AJAX_SUCCESS = "SAVE_ASSESSMENT_AJAX_SUCCESS";
export const PUBLISH_ASSESSMENT_AJAX_SUCCESS = "PUBLISH_ASSESSMENT_AJAX_SUCCESS";
export const ADD_ASSESSMENT = "ADD_ASSESSMENT";
export const UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT";
export const ASSOCIATE_TASK_TO_ASSESSMENT = "ASSOCIATE_TASK_TO_ASSESSMENT";
export const UNASSOCIATE_TASK_TO_ASSESSMENT = "UNASSOCIATE_TASK_TO_ASSESSMENT";
export const LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS = "LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS";
export const SAVE_MCAT_AJAX_SUCCESS = "SAVE_MCAT_AJAX_SUCCESS";
export const DELETE_MCAT_AJAX_SUCCESS = "DELETE_MCAT_AJAX_SUCCESS";

export function generateAssessmentTypeOptions() {
    return [
        { value: "Pilot", name: "Pilot" },
        { value: "Game", name: "Game" },
        { value: "Hotshot", name: "Hotshot" },
        { value: "Solo", name: "Solo" }
    ];
}