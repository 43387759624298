import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {
    getTasksData,
    saveTasksData,
    getTaskTypesData
} from "../../mockData/assessmentData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import {getCatModelTypeDescription} from "../../constants/catModelConstants";
import {taskDefaultState} from "./TaskReducer";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerTaskCatConfigApi {
    static removeTaskCatConfig(taskId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}taskCatConfigs?taskId=${taskId}`,
                RequestTypes.DELETE,
                ApiSuccessMessages.TASK_CAT_CONFIG_DELETED,
            )
        );
     }

    static addTaskCatConfig(taskCatConfig) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}taskCatConfigs`,
                RequestTypes.POST,
                JSON.stringify(taskCatConfig),
                ApiSuccessMessages.TASK_CAT_CONFIG_ADDED
            )
        );
    }

    static updateTaskCatConfig(taskCatConfig) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}taskCatConfigs`,
                RequestTypes.PUT,
                JSON.stringify(taskCatConfig),
                ApiSuccessMessages.TASK_CAT_CONFIG_UPDATED
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockTaskCatConfigApi {
    static getNewId() {
        const tasks = getTasksData();
        let taskCatConfigId = 0;
        tasks.map(task => {
            if (task.CatConfig.TaskCatConfigId > taskCatConfigId)
                taskCatConfigId = task.CatConfig.TaskCatConfigId;
        });

        return (taskCatConfigId + 1);
    }

    static removeTaskCatConfig(taskId) {
        let tasks = getTasksData();
        const otherTasks = tasks.filter(i => i.Id !== taskId);
        const task = tasks.filter(i => i.Id === taskId)[0];
        const taskTypes = getTaskTypesData();
        const catConfig = Object.assign({}, taskDefaultState().taskInfo.CatConfig, {TaskId: taskId});
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            const updatedTask =
                {
                    Id: task.Id,
                    Name: task.Name,
                    TaskTypeId: task.TaskTypeId,
                    TaskType: taskTypes.filter(f => f.Id === task.TaskTypeId)[0].Description,
                    TimeLimit: task.TimeLimit,
                    ItemsPerPage: task.ItemsPerPage,
                    GridRowTotal: task.GridRowTotal,
                    GridColumnTotal: task.GridColumnTotal,
                    GridSpacing: task.GridSpacing,
                    CatModelType: 0,
                    CatModelTypeDescription: "",
                    CatConfig: catConfig,
                    Forms: task.Forms,
                    NumberOfForms: task.Forms.length,
                    Links: task.Links
                };
            saveTasksData([...otherTasks, updatedTask]);
            return mockResolveApiCall(ApiSuccessMessages.TASK_CAT_CONFIG_DELETED);
        }
    }

    static addTaskCatConfig(taskCatConfig) {
        let tasks = getTasksData();
        const taskTypes = getTaskTypesData();
        const otherTasks = tasks.filter(i => i.Id !== taskCatConfig.TaskId);
        const task = tasks.filter(i => i.Id === taskCatConfig.TaskId)[0];
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            const newId = this.getNewId();
            const addTask = {
                Id: task.Id,
                Name: task.Name,
                TaskTypeId: task.TaskTypeId,
                TaskType: taskTypes.filter(f => f.Id === task.TaskTypeId)[0].Description,
                TimeLimit: task.TimeLimit,
                ItemsPerPage: task.ItemsPerPage,
                GridRowTotal: task.GridRowTotal,
                GridColumnTotal: task.GridColumnTotal,
                GridSpacing: task.GridSpacing,
                CatModelType: taskCatConfig.CatModelType,
                CatModelTypeDescription: getCatModelTypeDescription(taskCatConfig.CatModelType),
                CatConfig: {
                    Id: newId,
                    TaskId: task.Id,
                    CatModelType: taskCatConfig.CatModelType,
                    ItemPoolConfigs: taskCatConfig.ItemPoolConfigs,
                    ItemSelectionAlgorithm: taskCatConfig.ItemSelectionAlgorithm,
                    CustomThetaMin: taskCatConfig.CustomThetaMin,
                    CustomThetaMax: taskCatConfig.CustomThetaMax,
                    FixedLength: taskCatConfig.FixedLength,
                    Randomesque: taskCatConfig.Randomesque,
                    StopCriteria: taskCatConfig.StopCriteria,
                    ItemIds: taskCatConfig.ItemIds
                },
                Forms: task.Forms,
                NumberOfForms: task.Forms.length,
                Links: task.Links
            };
            saveTasksData([...otherTasks, addTask]);
            return mockResolveApiCall(ApiSuccessMessages.TASK_CAT_CONFIG_ADDED);
        }
    }

    static updateTaskCatConfig(taskCatConfig) {
        let tasks = getTasksData();
        const taskTypes = getTaskTypesData();
        const otherTasks = tasks.filter(i => i.Id !== taskCatConfig.TaskId);
        const task = tasks.filter(i => i.Id === taskCatConfig.TaskId)[0];
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            const editTask = {
                Id: task.Id,
                Name: task.Name,
                TaskTypeId: task.TaskTypeId,
                TaskType: taskTypes.filter(f => f.Id === task.TaskTypeId)[0].Description,
                TimeLimit: task.TimeLimit,
                ItemsPerPage: task.ItemsPerPage,
                GridRowTotal: task.GridRowTotal,
                GridColumnTotal: task.GridColumnTotal,
                GridSpacing: task.GridSpacing,
                CatModelType: taskCatConfig.CatModelType,
                CatModelTypeDescription: getCatModelTypeDescription(taskCatConfig.CatModelType),
                CatConfig: {
                    Id: taskCatConfig.TaskCatConfigId,
                    TaskId: task.Id,
                    CatModelType: taskCatConfig.CatModelType,
                    ItemPoolConfigs: taskCatConfig.ItemPoolConfigs,
                    ItemSelectionAlgorithm: taskCatConfig.ItemSelectionAlgorithm,
                    CustomThetaMin: taskCatConfig.CustomThetaMin,
                    CustomThetaMax: taskCatConfig.CustomThetaMax,
                    FixedLength: taskCatConfig.FixedLength,
                    Randomesque: taskCatConfig.Randomesque,
                    StopCriteria: taskCatConfig.StopCriteria,
                    ItemIds: taskCatConfig.ItemIds
                },
                Forms: task.Forms,
                NumberOfForms: task.Forms.length,
                Links: task.Links
            };
            saveTasksData([...otherTasks, editTask]);
            return mockResolveApiCall(ApiSuccessMessages.TASK_CAT_CONFIG_UPDATED);
        }
    }
}

const TaskCatConfigApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerTaskCatConfigApi : MockTaskCatConfigApi;
export default TaskCatConfigApi;